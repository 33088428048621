<template>
  <div
    class="register"
    :style="{ backgroundImage: 'url(' + bg + ')', backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat' }"
  >
    <el-row>
      <el-col
        class="registerBox"
        :xs="{ offset: '2', span: '20' }"
        :sm="{ offset: '4', span: '16' }"
        :md="{ offset: '12', span: '8' }"
        :lg="{ offset: '12', span: '8' }"
        :xl="{ offset: '12', span: '6' }"
      >
        <div class="title">找回密码</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="46px"
          class="demo-ruleForm"
        >
          <el-form-item label prop="name">
            <el-input
              type="text"
              v-model="ruleForm.name"
              @focus="inputIcon = ''"
              @blur="checkName"
              @input="change($event)"
              placeholder="请输入11位手机号"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="inputIcon == 'el-icon-circle-close'"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="inputIcon == 'el-icon-circle-check'"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item label prop="code">
            <el-row>
              <el-col :span="15">
                <el-input
                  v-model="ruleForm.code"
                  @input="change($event)"
                  placeholder="输入验证码"
                >
                  <i
                    slot="suffix"
                    style="color: red"
                    v-if="ruleForm.code.length != 6 && ruleForm.code.length > 0"
                    class="el-icon-circle-close"
                  ></i>
                  <i
                    slot="suffix"
                    style="color: green"
                    v-if="ruleForm.code.length == 6"
                    class="el-icon-circle-check"
                  ></i>
                </el-input>
              </el-col>
              <el-col :span="8" :offset="1" class>
                <el-button
                  :disabled="disableds"
                  @click.native="getMsg"
                  style="padding: 12px 6px; width: 100%"
                  >{{
                    disableds ? secendNo + "S后重新获取" : "发送验证码"
                  }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              @focus="checkPwd = null"
              @blur="checkPassword"
              @input="change($event)"
              placeholder="请输入密码"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="checkPwd"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="checkPwd === false"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>
          <p class="pwdtips" v-show="checkPwd">
            密码应为8-16个字符，至少包含数字和字母
          </p>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%; background: blue"
              @click.native="register"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "register",
  data() {
    return {
      bg: require("@/assets/images/logbgs.png"),
      inputIcon: "",
      ruleForm: {
        name: "",
        password: "",
        inPhone: "",
        code: "",
      },
      checkPwd: null,
      disableds: false,
      secendNo: 60,
      timer: null,
      rules: {
          name:[
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur"
          }
        ],
      },
      name: "",
      password: "",
      inPhone: "",
      code: "",
    };
  },

  mounted() {
    this.ruleForm.inPhone = location.href.split("?")[1];
  },
  methods: {
    gotoNext(val) {
      this.$router.push({name: val})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
    //校验手机号
    checkName() {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.ruleForm.name)) {
        this.inputIcon = "el-icon-circle-close";
        return false;
      } else {
        this.inputIcon = "el-icon-circle-check";
        return true;
      }
    },

    change() {
      this.$forceUpdate();
    },

    //校验密码
    checkPassword() {
      const str = /^[a-z0-9_-]{8,16}$/;
      this.checkPwd = !str.test(this.ruleForm.password);
    },
    /*
     *@date: 2019-11-06 20:17:52
     *@description: 获取验证码
     */
    //获取验证码
    getMsg() {
      if (this.ruleForm.name.length !== 11) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      const that = this;
     axios.post("/api/front/sendCode?phone=" +  that.ruleForm.name).then((res) => {
        if (res.data.code === 200) {
          that.$message({
            message: "发送成功",
            type: "success",
            center: true,
            offset: 300,
            duration: 2000,
          });
          if (!this.timer) {
            this.secendNo = 60;
            this.disableds = true;
            this.timer = setInterval(() => {
              if (this.secendNo > 0 && this.secendNo <= 60) {
                this.secendNo--;
              } else {
                this.disableds = false;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    /*
     *@date: 2019-11-07 15:49:09
     *@description:注册
     */
    register() {
      if (this.ruleForm.name === "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      if (this.ruleForm.code === "") {
        this.$message({
          message: "请输入验证码",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      if (this.ruleForm.password === "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      const that = this;
    
        if (undefined == that.ruleForm.inPhone) {
          that.ruleForm.inPhone = "";
        }
        const data = {
          account: that.ruleForm.name,
          captcha: that.ruleForm.code,
          password: that.ruleForm.password,
        };
        axios.post("/api/front/register/reset", data).then((res) => {
          if (res.code === 200) {
            that.$message({
              message: "密码修改成功",
              type: "success",
              center: true,
              offset: 300,
              duration: 2000,
            });
            
          } else {
            that.$message({
              message: res.data.message,
              type: "warning",
              center: true,
              offset: 300,
              duration: 1000,
            });
          }
        });
   
    },
    /*
     *@date: 2019-11-10 17:25:48
     *@description: 前往登录
     */
    tologin() {
      this.$router.push("/login");
    },
    //获取未读信息
    getMsgs() {
      const data = { type: 1, page: 1, limit: 10000 };
     axios.post("/wealth/getMsg", data).then((res) => {
        this.$store.commit("setInfo", ["totalSize", res.data.data.totalSize]);
      });
    },
    //获取用户信息
    getUserInfo() {
      const data = {};
     axios.post("/wealth/getUserInfo", data).then((res) => {
        if (res.data.code === 0) {
         
          this.$store.commit("setInfo", ["userInfo", res.data.data]);
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  border-top: 1px dashed #999;
}
.iconBox {
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    width: 22px;
    height: 22px;
    display: inline-block;
  }
}
.noMargrin {
  margin-bottom: 0;
}
.pwdtips {
  margin: 5px 0 10px;
  text-align: left;
  text-indent: 46px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: orange;
}
.pointer {
  cursor: pointer;
}
.registerBox {
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 70px;
  margin-bottom: 85px;
  .title {
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    margin: 17px 0;
  }
  .demo-ruleForm {
    padding-right: 74px;
  }
  .btnBox {
    border: 1px solid #dcdfe6;
    line-height: 38px;
  }
}
</style>